@import 'colors.scss';
@import 'fonts.scss';
.App {
  padding-top: 70px; 
  font-size: 18px;
  * {
    font-family: $primary-font;
  }
  button {
    border: none;
    background: none;
    color: $primary;
    font-weight: bold;
    font-size: 1em;
  }
  button:focus {
    opacity: 0.8;
  }
  h1 {
    color: $secondary;
  }
}
.Logo {
  fill: $primary;
  svg {
    padding-top: 10px;
  }
  * {
    fill: $primary;
  }
}
.Navbar {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  .Navbar-1, .Navbar-2, .Navbar-3 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Navbar-1 {
    width: 40%;
    justify-content: flex-start;
    button {
      height: 70px;
      width: 70px;

    }
  }
  .Navbar-2 {
    width: 60%;
  }
  .Navbar-3 {
    width: 40%;
    justify-content: flex-end;
    button {
      height: 70px;
      width: 70px;
    }
    .action-button {
      width: 140px;
      background-color: $primary;
      color: $white;
    }
  }
  @media (max-width: 650px) {
    .Navbar-1 {
      width: 40%;
      display: none;
      justify-content: flex-start;
      button {
        height: 70px;
        width: 70px;
  
      }
    }
    .Navbar-2 {
      width: 40%;
      justify-content: flex-start;
      padding-left: 10px;
      svg  {
        height: 30px;
      }
    }
    .Navbar-3 {
      width: 60%;
      justify-content: flex-end;
      button {
        height: 70px;
        width: 70px;
      }
      .action-button {
        width: 140px;
        background-color: $primary;
        color: $white;
      }
    }
  }
  
}

.Footer {
  background-color: $secondary;
  .Footer-container {
    padding: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      color: $white;
      text-decoration: none;
    }
    .Logo {
      fill: $white;
      svg {
        height: 50px;
      }
      * {
        fill: $white;
      }
    }
  }
}

.HomeHeader {
  height: 700px;
  background-color: $secondary;
  position: relative;
  overflow: hidden;
  .button-container {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 90%;
    button {
      background-color: $primary;
      color: $white;
      padding: 15px;
      width: 100%;
      margin-bottom: 15px;
    }
    :nth-child(2) {
      button {
        background: $secondary;
      }
    }
  }
  video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  @media (max-height: 900px) {
    height: 400px;

  }
}

.NewProductsTagLine {
  padding: 15px;
  margin-top: 100px;
  font-weight: bolder;
  #top {
    color: $primary;
  }
  #middle {
    color: $secondary;
  }
}

.Quote {
  padding: 70px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $primary;

  p, span {
    color: white;
    font-weight: bold;
    text-align: center;
  }
  p::before, p::after {
    font-size: 2em;
    content: "\""
  }
  span {
    font-weight: 300;
    display: block;
  }
  span::before {
    content: '- '
  }
}


.Pricing {
  display: flex;
  align-items: center;
  padding: 25px;
  flex-direction: column;
  @media (min-width: 1300px){
   
  }
  .Pricing-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    grid-auto-rows: auto;
    .PricingCard {
      background: white;
      border: 3px solid $secondary;
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 270px;
      flex-direction: column;
      justify-content: flex-start;
      
      header {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $secondary;
        width: 100%;
        height: 50px;
        color: white;
        font-size: 1.6em;
        font-weight: bolder;
      }
      .content {
        padding: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        h2 {
          text-align: center;
          color: $primary
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          padding-bottom: 25px;
          li {
            margin-top: 2px;
            text-align: center;
          }
        }
        a {
          width: 90%;

        }
        button {
          background: $secondary;
          color: white;
          width: 100%;
          height: 40px;
          margin-top: 15px;
        }
        #action-bttn {
          background: none;
          color: $primary;
          border: 3px solid $primary;
        }
        
      }
    }
    @media (max-width: 870px) {
      grid-template-columns: repeat(2, 1fr);

    }
    @media (max-width: 590px) {
      grid-template-columns: repeat(1, 1fr);

    }
  }
  .PricingCard:last-child {
    
  }
}

.BonusPoints  {
  display: flex;
  justify-content: center;
  background-color: $primary;
  padding: 25px;
  .BonusPoints-container {
    width: 100%;
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    padding: 0px 0;
    @media (max-width: 870px) {
      grid-template-columns: repeat(2, 1fr);

    }
    @media (max-width: 590px) {
      grid-template-columns: repeat(1, 1fr);

    }
    .point {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border: 3px solid  $white;
      h1 {
        color: $white;
        font-size: 1em;
        font-weight: normal;
      }
      .icon {
        height: 20px;
        width: 20px;
        margin-right: 15px;
        svg {
          width: 100%;
        }
        * {
          fill: $white;
        }
      }
    }
  }
}

.WhatYouGet  {
  background-color: $primary;
  display: flex;
  justify-content: center;
  padding: 25px;
  padding-bottom: 50px;
  .WhatYouGet-container  {
    background-color: $secondary;
    height: 300px;
    max-width: 900px;
    width: 100%;
    display: flex;
    .left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $white;
      h1 {
        color: white;
        margin-bottom: 10px;
        margin-top: 0;
      }
      span {
         display: block;
         height: 1px;
         width: 50px;
         background-color: white;
      }
      ul {
        margin: 0;
        padding: 0;
        text-align: center;
        list-style: none;
        li {
          margin-top: 15px;
        }
      }
    }
    .right {
      width: 50%;
      height: 100%;
      .right-img-container {
        height: 100%;
        width: 100%;
        background-color: $primary-dark;
      }
    } 

    @media  (max-width: 850px) {
      flex-direction: column-reverse;
      height: 100%;
      .left,.right {
        width: 100%;
        min-height: 300px;
        height: 300px;
        h1 {
          font-size: 1.5em;
        }
      }
    }
  }
}

.PastBoxes {
  height: 300px;
  background-color: $secondary;
  display: flex;
  .box  {
    height: 100%;
    width: 25%;
    .tint {
      height: 100%;
      width: 100%;
      background-color: $secondary;
      opacity: 0;
    }
    .tint:hover {
      //animation: opacity 0.5s forwards;
    }
    @keyframes opacity {
      from {
        opacity: 0;
      }
      to {
        opacity: .8;
      }
    }
  }
  @media (max-width: 1200px) {
    :nth-child(1) {
      display: none
    }
    .box {
      width: 33%;
    }
  }
  @media (max-width: 860px) {
    :nth-child(1), :nth-child(2) {
      display: none
    }
    .box {
      width: 50%;
    }
  }
  @media (max-width: 560px) {
    :nth-child(1), :nth-child(2),:nth-child(3) {
      display: none
    }
    .box {
      width: 100%;
    }
  }
}

.BigReview {
  height: 500px;
  background: $primary;
  display: flex;
  justify-content: center;
  padding: 50px 25px;
  .BigReview-container {
    width: 100%;
    max-width: 900px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    .Logo {
      width: 200px;
      fill: $white;
      *  {
        fill: inherit;
      }
    }
    .content {
      margin: 50px 0;
      padding-top: 30px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;
      border: 6px solid $white;
      height: 400px;
      color: $white;
      @media (max-width: 500px){
        border: none;
        width: 100%;
      }
    }
    p {
      padding: 30px;
      padding-bottom: 0;
      text-align: center;
      line-height: 1.8em;
    }
    p::before {
      font-family: $secondary-font;
      position: absolute;
      left: 23px;
      content: open-quote;
      font-size: 2em;
    }
    p::after {
      font-family: $secondary-font;
      position: absolute;
      content: close-quote;
      font-size: 2em;

    }
    span {
      display: block;
      font-style: italic;
    }
    .stars {
      top: -50px;
      position: absolute;
      padding: 25px;
      background: $secondary;
      display: flex;
      svg {
        height: 35px;
        width: 45px;
      }
      .active {
        fill: $white
      }
      @media (max-width: 300px) {
        padding:  0;
        svg {
          height: 25px;
          width: 35px;
        }
      }
    }
  }
}

.ArtistProfitShare {
  margin: 100px 0;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  .ArtistProfitShare-container {
    background-color: $secondary;
    max-width: 900px;
    width: 100%;
    height: 500px;
    display: flex;
    .right {
      height: 100%;
      width: 50%;
      background-color: $secondary-light;
      
    }
    .left {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;
      img {
        height: 75px;
        width: 75px;
        padding: 30px;
        
      }
      h1 {
        color: white;
        margin-bottom: 10px;
        margin-top: 0;
        font-family: $secondary-font;
        font-weight: bolder;
      }
      span {
         display: block;
         height: 1px;
         width: 50px;
         background-color: white;
      }
      p {
        color: $white;
        text-align: center;
        line-height: 1.8em;
      }
      ul {
        margin: 0;
        padding: 0;
        text-align: center;
        list-style: none;
        li {
          margin-top: 15px;
        }
      }
    }
    @media (max-width: 700px) {
     flex-direction: column-reverse;
     height: auto;
     .left,.right {
       width: 100%;
       min-height: 400px;
      padding: 0;
      p {
        padding: 25px
      }
     }
    }
  }
}


.CheckoutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  .CheckoutPage-container {
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    .CheckoutPage-Heading {
      margin-top: 50px;
      background: $white;
      padding: 0  15px;
      position: relative;
    }
    .CheckoutPage-Heading::after  {
      
      content: "";
      z-index: -1;
      position: absolute;
      top: 50%;
      left: -50%;
      right: 0;
      width: 200%;
      height: 3px;
      opacity: .8;
      background-color: $black;

    }
    .item {
      display: flex;
      border: 3px solid $secondary;
      width: 90%;
      .item-avatar {
        height: 140px;
        background: $primary-dark;
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          margin: 0;
          color: $white;
        }
        span {
          color: $white;
        }
      }
      .item-content  {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          font-size: 1em;
          padding: 0;
          margin: 0;
        }
        p  {
          padding: 0;
          margin: 0;
          font-size: .7em;
          color: $grey;
        }
        h2 {
          margin: 0;
          padding: 0;
          font-size: 1em;
          margin: 10px 0;
          color: $primary-dark;
        }
      }
    }
    a {
      margin: 25px;
      color: $primary-dark;
    }
    button {
      background: $primary;
      color: $white;
      padding: 15px 0px;
      width: 100%;
      max-width: 600px;
    }
  }
}

.SuccessPage {
  height: 80vh;
  width: 100%;
  background: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
    .SuccessPage-container {
    width: 100%;
    max-width: 900px;
    height: 350px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:  $primary-dark;
    * {
      text-align: center;
      padding: 0 25px;
    }
  }
}

.CreateAccount {
  height: 80vh;
  width: 100%;
  background: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
    .CreateAccount-container {
    width: 100%;
    max-width: 900px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:  $primary-dark;
    * {
      text-align: center;
      padding: 0 25px;
    }
    h2 {
      color: $primary-dark;
      font-size: 1em;
    }
    label {
      color: $secondary;
      font-size: .8em;
      width:200px;
      text-align: left;
    }
    input {
      width: 200px;
      margin: 10px 0;
      padding: 5px;
      text-align: left;
    }
    button {
      margin: 10px -0;
      padding: 10px 0;
      width: 100%;
      max-width: 210px;
      background: $secondary;
      color: white;
    }
    a {
      color: $primary;
      margin-top: 15px;
      margin-bottom: 15px;
    }

  }
}

.UpcomingBox {
  display: flex;
  justify-content: center;

  .UpcomingBox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 50px 15px;
    padding: 50px 0;
    min-height: 200px;
    width: 100%;
    max-width: 900px;
    background: $secondary-light;
    text-align: center;
    h1 {
      color: $white;
      font-size: 1.2em;
      padding: 5px 10px;
      border: 3px solid $white;
      margin: 0;
    }
    h2 {
      font-size: 1em;
      color: $white;
      padding: 0;
      margin: 15px 0;
      font-weight: normal;
    }
    a {
      color: $secondary;
      text-decoration: none;
      background-color: white;
      padding: 5px 20px;
    }
  }
}