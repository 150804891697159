

$white: #ffffff;
$black: #364d5b;
$grey: #b9b9b9;

$primary: #85D5C7;
$primary-light: #A4E0D6;
$primary-dark: #67CBBA;

$secondary: #364D5B;
$secondary-light: #446174;
$secondary-dark: #263640;